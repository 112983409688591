import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../../Page/login";
import StudentFormPg from "../../Page/StudentFormPg";
import Employeecreation from "../../Page/Employeecreation";
import NewQuotation from "../../Page/NewQuotation";
import UploadPayslip from "../../Page/Uploadpayslip";
import Downloadpayslip from "../../Page/Downloadpayslip";
import CustomerCreation from "../../Page/Customercreation";
import Viewsales from "../../Page/viewsales";
import Viewcustomertable from "../../Page/Viewcustomertable";
import Viewquotation from "../../Page/ViewQuotation";
import SalesInvoice from "../../Page/SalesInvoice";
import StudentViewTable from "../../Page/StudentViewTable";
import { QueryClient, QueryClientProvider } from "react-query";
import Downloadquotation from "../../Page/Downloadquotation";
import PaySlipDownloadPage from "../../Page/PaySlipDownloadPage";
import ViewEmployeeScreen from "../../Page/ViewEmployeeScreen";
import AddSalesScreen from "../../Page/AddSalesScreen";
import CoursePaymentScreen from "../../Page/StudentPayslip";
import { useSelector } from "react-redux";
export default function Router() {
  const queryClient = new QueryClient();
  const isLoggedIn = useSelector((state) => state?.login);
  const employeeRole = useSelector((state) => state?.employeeDetails);
  // console.log("ROLEKKK", employeeRole);
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {!isLoggedIn ? (
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        ) : employeeRole?.role === "superAdmin" ? (
          <Routes>
            <Route path="/" element={<Employeecreation />} />
            <Route path="/StudentFormPg" element={<StudentFormPg />} />
            <Route path="/AddSales" element={<AddSalesScreen />} />
            {/* <Route path="/" element={<StudentcreationForm />} /> */}
            {/* <Route path="/"element={<StudentcreationForm />} /> */}
            {/* <Route path="/StudentCreationForm" element={<StudentcreationForm />} */}
            {/* <Route path="/login" element={<About />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/NewQuotation" element={<NewQuotation />} />
            {/* <Route path="/" element={<Employeecreation />} /> */}
            <Route path="/UploadPayslip" element={<UploadPayslip />} />
            <Route path="/Downloadpayslip" element={<Downloadpayslip />} />
            <Route path="/SalesInvoive" element={<SalesInvoice />} />

            {/* <Route path="/" element={<Employeecreation />} /> */}
            {/* <Route path="/employeecreation" element={<CustomerCreationForm />} /> */}
            <Route path="/AddCustomer" element={<CustomerCreation />} />
            {/* <Route path="/ViewCustomer" element={<ViewCustomer />} /> */}
            <Route
              path="/PaySlipDownloadPage"
              element={<PaySlipDownloadPage />}
            />
            <Route path="/ViewCustomer" element={<Viewcustomertable />} />

            {/* <Route path="/viewemployee" element={<Viewemployee />} /> */}
            <Route path="/viewsales" element={<Viewsales />} />
            <Route path="/viewquotation" element={<Viewquotation />} />
            <Route path="/viewemployeeTable" element={<ViewEmployeeScreen />} />
            <Route path="/StudentViewTable" element={<StudentViewTable />} />
            <Route path="/StudentViewPay" element={<CoursePaymentScreen />} />
            <Route path="/downloadQuotation" element={<Downloadquotation />} />
          </Routes>
        ) : employeeRole?.role === "admin" || employeeRole?.role === "sales" ? (
          <Routes>
            {/* <Route path="/" element={<Employeecreation />} /> */}

            <Route path="/StudentFormPg" element={<StudentFormPg />} />
            <Route path="/AddSales" element={<AddSalesScreen />} />
            {/* <Route path="/" element={<StudentcreationForm />} /> */}
            {/* <Route path="/"element={<StudentcreationForm />} /> */}
            {/* <Route path="/StudentCreationForm" element={<StudentcreationForm />} */}

            {/* <Route path="/login" element={<About />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/NewQuotation" element={<NewQuotation />} />
            {/* <Route path="/" element={<Employeecreation />} /> */}
            <Route path="/" element={<UploadPayslip />} />
            <Route path="/Downloadpayslip" element={<Downloadpayslip />} />
            <Route path="/SalesInvoive" element={<SalesInvoice />} />

            {/* <Route path="/" element={<Employeecreation />} /> */}
            {/* <Route path="/employeecreation" element={<CustomerCreationForm />} /> */}
            <Route path="/AddCustomer" element={<CustomerCreation />} />
            {/* <Route path="/ViewCustomer" element={<ViewCustomer />} /> */}
            <Route
              path="/PaySlipDownloadPage"
              element={<PaySlipDownloadPage />}
            />
            <Route path="/ViewCustomer" element={<Viewcustomertable />} />

            {/* <Route path="/viewemployee" element={<Viewemployee />} /> */}
            <Route path="/viewsales" element={<Viewsales />} />
            <Route path="/viewquotation" element={<Viewquotation />} />
            <Route path="/viewemployeeTable" element={<ViewEmployeeScreen />} />
            <Route path="/StudentViewTable" element={<StudentViewTable />} />
            <Route path="/StudentViewPay" element={<CoursePaymentScreen />} />
            <Route path="/downloadQuotation" element={<Downloadquotation />} />
          </Routes>
        ) : (
          <Routes>
            {/* <Route path="/" element={<Employeecreation />} />
                    <Route path="/StudentFormPg" element={<StudentFormPg />} />
                    <Route path="/AddSales" element={<AddSalesScreen />} /> */}
            {/* <Route path="/" element={<StudentcreationForm />} /> */}
            {/* <Route path="/"element={<StudentcreationForm />} /> */}
            {/* <Route path="/StudentCreationForm" element={<StudentcreationForm />} */}

            {/* <Route path="/login" element={<About />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            {/* <Route path="/NewQuotation" element={<NewQuotation />} /> */}
            {/* <Route path="/" element={<Employeecreation />} /> */}
            {/* <Route path="/UploadPayslip" element={<UploadPayslip />} /> */}
            <Route path="/" element={<Downloadpayslip />} />
            {/* <Route path="/SalesInvoive" element={<SalesInvoice />} /> */}

            {/* <Route path="/" element={<Employeecreation />} /> */}
            {/* <Route path="/employeecreation" element={<CustomerCreationForm />} /> */}
            {/* <Route path="/AddCustomer" element={<CustomerCreation />} /> */}
            {/* <Route path="/ViewCustomer" element={<ViewCustomer />} /> */}
            <Route
              path="/PaySlipDownloadPage"
              element={<PaySlipDownloadPage />}
            />
            {/* <Route path="/ViewCustomer" element={<Viewcustomertable />} /> */}

            {/* <Route path="/viewemployee" element={<Viewemployee />} /> */}
            {/* <Route path="/viewsales" element={<Viewsales />} />
                    <Route path="/viewquotation" element={<Viewquotation />} />
                    <Route path="/viewemployeeTable" element={<ViewEmployeeScreen />} />
                    <Route path="/StudentViewTable" element={<StudentViewTable />} />
                    <Route path="/StudentViewPay" element={<CoursePaymentScreen />} />
                    <Route path="/downloadQuotation" element={<Downloadquotation />} /> */}
          </Routes>
        )}
      </BrowserRouter>
    </QueryClientProvider>
  );
}
