import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";

import { useLocation, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "react-query";
import axios from "axios";
import PopupModal from "../popupmodal/PopupModal";

export default function EmployeeCreationform() {
  const [startDate, setStartDate] = useState(new Date());
  const [checkPassword, setCheckPassword] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.log("state", state);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const handleNavigate = () => {
    navigate("/viewemployeeTable");
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const { mutate, isLoading, isError } = useMutation(
    (postData) =>
      axios.post(
        "https://api.2kvirtualworld.com/EMS/employee/postEmployee",
        postData
      ),
    {
      onSuccess: (data) => {
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
        // navigate("/viewemployeeTable");
      },
    }
  );

  const updateData = useMutation(
    (postData) =>
      axios.put(
        `https://api.2kvirtualworld.com/EMS/employee/update/${state[0]?.employeeId}`,
        postData
      ),
    {
      onSuccess: (data) => {
        // navigate("/viewemployeeTable");
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
    }
  );

  useEffect(() => {
    if (state) {
      setValue("employeeId", state[0]?.employeeId);
      setValue("employeeName", state[0]?.employeeName);
      setValue("description", state[0]?.description);
      // setValue('password',state[0]?.password)
      // setValue('confirmPassword',state[0]?.confirmPassword)
      setValue("role", state[0]?.role);
      setValue("phoneNumber", state[0]?.phoneNumber);
      setValue("alternateNumber", state[0]?.alternateNumber);
      setValue("emailId", state[0]?.emailId);
      setValue("aadharNumber", state[0]?.aadharNumber);
      setValue("dob", state[0]?.dob);
      setValue("flatNo", state[0]?.flatNo);
      setValue("street", state[0]?.street);
      setValue("area", state[0]?.area);
      setValue("city", state[0]?.city);
      setValue("pincode", state[0]?.pincode);
      setValue("joiningDate", state[0]?.joiningDate);
      setValue("relievingDate", state[0]?.relievingDate);
      setValue("bankName", state[0]?.bankName);
      setValue("bankAccountNumber", state[0]?.bankAccountNumber);
      setValue("ifscCode", state[0]?.ifscCode);
    }
  }, [state]);

  const { employeeID, confirmPassword, password } = watch();

  console.log("watch", employeeID);
  const onSubmit = (data) => {
    console.log("dat_form", data);
    const formValue = {
      employeeId: data?.employeeId,
      employeeName: data?.employeeName,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
      // encryptedPwd: "encryptedPasswordHash",
      role: data?.role,
      description: data?.description,
      phoneNumber: data?.phoneNumber,
      alternateNumber: data?.alternateNumber,
      emailId: data?.emailId,
      aadharNumber: data?.aadharNumber,
      dob: data?.dob,
      flatNo: data?.flatNo,
      street: data?.street,
      area: data?.area,
      city: data?.city,
      pincode: data?.pincode,
      joiningDate: data?.joiningDate,
      relievingDate: data?.relievingDate,
      bankName: data?.bankName,
      bankAccountNumber: data?.bankAccountNumber,
      ifscCode: data?.ifscCode,
    };
    if (state && state[0]?.employeeId) {
      updateData.mutate(formValue);
    } else if (password === confirmPassword) {
      mutate(formValue);
    } else if (password !== confirmPassword) {
      setCheckPassword("Check and re-enter your password.");
    }
  };

  return (
    <>
      <div className="EmployeeCreationformbg">
        <form className="EmployeeForm py-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="empform">
            <div className="empformleft">
              <label className="empl">Employee ID</label>
              <input
                type="text"
                placeholder="employee id"
                className="inputeid"
                {...register("employeeId")}
              />
              <label className="empl">Role</label>
              <input
                type="text"
                placeholder="Eg:front end developer"
                className="inputeid"
                {...register("role")}
              />

              <label className="empl">Phone Number</label>

              <input
                type="text"
                placeholder="Phone No"
                className="inputeid"
                {...register("phoneNumber", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                })}
              />
              {isSubmitted && errors.phoneNo && <p> Invalid Phone Number </p>}

              <label className="empl">Email Id</label>

              <input
                type="text"
                placeholder="Email id"
                className="inputeid"
                {...register("emailId", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                })}
              />
              {isSubmitted && errors.email && (
                <p>Invalid email please enter the valid email </p>
              )}
              <label className="empl">Date of Birth</label>
              <Controller
                control={control}
                name="dob"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    placeholderText="DOB"
                    showYearDropdown
                    selected={value}
                    onChange={(date) => {
                      onChange(date);
                      setStartDate(date);
                    }}
                    className="inputeid"
                  />
                )}
              />
              {/* <input type="text" placeholder="dd-mm-yyyy" className="inputeid" /> */}

              <label className="empl">Street</label>
              <input
                type="text"
                placeholder="Street name"
                className="inputeid"
                {...register("street")}
              />
              <label className="empl">City</label>
              <input
                type="text"
                placeholder="City Name"
                className="inputeid"
                {...register("city")}
              />
              <label className="empl">Bank Name</label>
              <input
                type="text"
                placeholder="Bank Name"
                className="inputeid"
                {...register("bankName")}
              />
              <label className="emp1">IFSC Code</label>

              <input
                type="text"
                placeholder="IFSC CODE"
                className="inputeid"
                {...register("ifscCode")}
              />
              <label className="empl">Date of Joining</label>
              <Controller
                control={control}
                name="joiningDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value}
                    onChange={(date) => {
                      onChange(date);
                    }}
                    className="inputeid"
                  />
                )}
              />
              <label className="empl">Confirm Password</label>
              <input
                type="password"
                placeholder="Conform Passwprd"
                className="inputeid"
                {...register("confirmPassword")}
              />
              {checkPassword && (
                <i style={{ color: "red", fontSize: "16px" }}>
                  {checkPassword}
                </i>
              )}
            </div>
            <div className="empformright">
              <label className="empl">Employee Name</label>
              <input
                type="text"
                placeholder="name"
                className="inputeid"
                {...register("employeeName")}
              />
              <label className="empl">Description</label>
              <input
                type="text"
                placeholder="Developer"
                className="inputeid"
                {...register("description")}
              />
              <label className="empl"> Alternate Number</label>
              <input
                type="text"
                placeholder="Alternate no"
                className="inputeid"
                {...register("alternateNumber")}
              />
              <label className="empl"> Aadhar Number</label>
              <input
                type="text"
                placeholder="Aadhar No"
                className="inputeid"
                {...register("aadharNumber")}
              />

              <label className="empl">Flat No</label>
              <input
                type="text"
                placeholder="Flat No"
                className="inputeid"
                {...register("flatNo")}
              />
              <label className="empl">Pin code</label>
              <input
                type="text"
                placeholder="Pin code"
                className="inputeid"
                {...register("pincode", {
                  required: true,
                  pattern: { value: /^[0-9]{4,6}$/ },
                })}
              />
              {isSubmitted && errors.pincode && <p>Invalid pincode</p>}
              <label className="empl">Area</label>
              <input
                type="text"
                placeholder="Area"
                className="inputeid"
                {...register("area")}
              />
              <label className="empl">Bank Account Number</label>
              <input
                type="text"
                placeholder="account number"
                className="inputeid"
                {...register("bankAccountNumber")}
              />
              <label className="empl">Date of Reliving</label>
              <Controller
                control={control}
                name="relievingDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    placeholderText="Date of reliving"
                    selected={value}
                    onChange={(date) => {
                      onChange(date);
                      setStartDate(date);
                    }}
                    className="inputeid"
                  />
                )}
              />
              <label className="empl">password</label>
              <input
                type="password"
                placeholder="Passwprd"
                className="inputeid"
                {...register("password")}
              />

              {/* <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="inputeid"
            /> */}
              <div className="empsub">
                {/* <button className="empsubbutton">Submit</button> */}
                <button className="empsubbutton" type="submit">
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                  {/* save */}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="NEW EMPLOYEE HAS BEEN ADDED SUCCESSFULLY"
        onDone={handleNavigate}
        updateMessage="EMPLOYEE HAS BEEN UPDATED SUCCESSFULLY"
        stateID={state && state}
      />
    </>
  );
}
