import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import Images from "../../../asstes";
import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
export default function ViewQuotationTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDelete, setIsDelete] = useState()
  const navigate = useNavigate()
  const fetchDataManagement = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/EMS/quotation/getQuotation`
    );
  };

  const { data, isLoading, isError, refetch, isFetched } = useQuery(
    "data",
    fetchDataManagement
  );
  // console.log("data_jjkk", data?.data)
  // DELETE START

  const deletetogglePopup = async (state) => {
    console.log("DDSA:", state);

    try {
      await axios.delete(
        `https://api.2kvirtualworld.com/EMS/quotation/deleteQuotation/${state}`
      );
    } catch (error) {
      console.log("Error", error);
    }
    refetch()
    // setPopupOpen(false);
  };
  // DELETE END
  // API DATA CHECK
  const [selectedCustomer, setSelectedCustomer] = useState(data);
  useEffect(() => {

    try {
      console.log("Fetched Data:", data?.data);
      console.log("Customer ID to match:", selectedCustomer);

      // Filter the data based on the customerId
      const values = data?.data?.filter(
        (ele) => String(ele?.customerId) === String(selectedCustomer)
      );

      if (values?.length > 0) {
        console.log("Matching customer found:", values);
        // Navigate to NewQuotation page with the matching data
        navigate("/NewQuotation", { state: values });
      } else {
        console.warn("No matching customer found.");
      }
    } catch (error) {
      console.error("Error during navigation:", error);
    }


  }, [data, selectedCustomer]);
  const [selectedCustomerdownload, setSelectedCustomerDownload] = useState(data);
  useEffect(() => {

    try {
      console.log("Fetched Data:", data?.data);
      console.log("Customer ID to match:", selectedCustomerdownload);

      // Filter the data based on the customerId
      const values = data?.data?.filter(
        (ele) => String(ele?.customerId) === String(selectedCustomerdownload)
      );

      if (values?.length > 0) {
        console.log("Matching customer found:", values);
        // Navigate to NewQuotation page with the matching data
        navigate("/downloadQuotation", { state: values });
      } else {
        console.warn("No matching customer found.");
      }
    } catch (error) {
      console.error("Error during navigation:", error);
    }


  }, [data, selectedCustomerdownload]);

  // useEffect(() => {
  //   const navigationss = (value) => {
  //     // try {
  //     // Log the data for debugging purposes
  //     console.log("Fetched Data:2", selectedCustomer, value);

  //     // Filter the data based on the customerId
  //     const values = data?.data?.filter(
  //       (ele) => ele?.customerId === value
  //     );

  //     if (values?.length > 0) {
  //       // Store the filtered data in sessionStorage
  //       navigate("/NewQuotation", { state: values });
  //     } else {
  //       console.warn("No matching customer found.");
  //     }

  //   }
  //   navigationss()
  // }, [data])
  console.log("Fetched Data:", selectedCustomer);
  const columns = useMemo(
    () => [
      { Header: "Select", accessor: "select" },
      { Header: "Customer ID", accessor: "customerId" },
      // { Header: "Gender", accessor: "genderTitle" },
      { Header: "Customer Name", accessor: "customerName" },
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Phone No", accessor: "phoneNumber" },
      { Header: "Address", accessor: "address" },
      { Header: "City", accessor: "city" },
      { Header: "Pincode", accessor: "pincode" },
      { Header: "State", accessor: "state" },
      { Header: "Need", accessor: "need" },
      { Header: "Service", accessor: "server" },
      { Header: "Description", accessor: "addDescription" },
      { Header: "Server Cost", accessor: "serverCost" },
      { Header: "Need Cost", accessor: "needCost" },
      // { Header: "Flow Diagram", accessor: "flowDiagram" },
      { Header: "AMC Duration", accessor: "amcDuration" },
      {
        Header: "Product Copy",
        accessor: "productCopy",
        Cell: ({ row }) => (
          // console.log("FFFFF", )

          <div>
            <button
              onClick={() => deletetogglePopup(row.original.customerId)}
              style={{ marginRight: "10px", cursor: "pointer", border: "none", background: "transparent" }}
            >
              🗑️
            </button>
            <button
              onClick={() => setSelectedCustomer(row.original.customerId)}
              style={{ cursor: "pointer", border: "none", background: "transparent" }}
            >
              ✏️
            </button>
          </div>
        ),
      },
      {
        Header: "Download Quotation",
        accessor: "downloadquotation",
        Cell: ({ row }) => (
          // console.log("FFFFF", )

          <div>
            <button
              onClick={() => setSelectedCustomerDownload(row.original.customerId)}
              style={{ cursor: "pointer", border: "none", background: "transparent", width: "40px", height: "25px", objectFit: "contain" }}
            >
              <img src={Images.downloadIcon} alt="downolad" style={{
                width: "100%", height: "100%",
                // objectFit: "contain"
              }} />
            </button>
          </div>
        ),
      },
    ],
    [navigate]
  );


  const tabelcolums = data?.data?.map((item) => ({
    ...item,
    address: `${item.flatNo}, ${item.street}, ${item.area}, ${item.city}, ${item.state} - ${item.pincode}`,
    select: <input type="checkbox" />,
  }));

  const filteredData = tabelcolums && tabelcolums ? tabelcolums && tabelcolums?.filter(
    (item) =>
      item.customerName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.customerId?.toLowerCase().includes(searchTerm?.toLowerCase())
  ) : [];
  const tableInstance = useTable({ columns, data: filteredData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  console.log("tabelcolums", tabelcolums, "columns", columns, "tableInstance", tableInstance);

  return (
    <div className="customerviewtablebg" style={{ overflowY: "hidden" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0px 20px" }}>

        <div className="customerviewsearch" style={{ display: "flex", alignItems: "center", background: "#fff", width: "20%", margin: "15px 25px", borderRadius: "5px", padding: "10px 3px", border: '2px solid #7a1cac' }}>
          <input type="text" className=" customerviewtableinput" onChange={(e) => setSearchTerm(e.target.value)} style={{ border: "none", outline: "none", borderRadius: "0px", height: "20PX" }}></input>
          <div style={{ width: '22px' }}>

            <img src={Images.searchIcon} alt="searchIcon" />
          </div>
        </div>
        <div>
          {/* <div style={{ width: "40px", height: "40px" }}>

            <img src={Images.downloadIcon} width="100%" height="100%" alt="download" />
          </div> */}
        </div>
      </div>

      <div className="customerviewincontainer">
        <table
          cellPadding={"10px"}
          cellSpacing={"0px"}
          className="customerviewtable"
        >
          <thead>
            {
              headerGroups.map((headerGroup) => (

                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map((column) => (

                      <th className="viewcustomerheader"  {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))
                  }

                </tr>
              ))
            }
          </thead>


          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className="viewcustomerdata" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center", padding: "10px" }}>
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <div className="table-container">
        <table
          cellPadding={"10px"}
          cellSpacing={"0px"}
          className="customerviewtable"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    className={`viewcustomerdata ${index === 0 ? "sticky-column" : ""
                      }`}
                    // className={`viewcustomerheader ${index === 0 ? "sticky-header-column" : ""
                    //   }`} // Add sticky class for the first column header
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => (
                      <td
                        className={`viewcustomerdata ${index === 0 ? "sticky-column" : ""
                          }`} // Add sticky class for the first column
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> */}

    </div>
  );
}
