import axios from "axios";

export const getViewCustomer = async () => {
  return await axios.get(`https://api.2kvirtualworld.com/EMS/customers`);
};
export const nextCustomerId = async () => {
  return await axios.get(
    `https://api.2kvirtualworld.com/EMS/quotation/nextCustomerId`
  );
};

export const getViewStudent = async () => {
  return await axios.get(
    `https://api.2kvirtualworld.com/EMS/students/getStudent`
  );
};

export const fetchDataManagement = async () => {
  return await axios.get(`https://api.2kvirtualworld.com/EMS/sales/getSales`);
};
