import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import Viewsalescom from "../Common/component/Viewsalestable/Viewsalescom";
// import Viewsalescom from '../Common/component/Viewsalescom/Viewsalescom'

export default function Viewsales() {
  return (
    <section>
      <div
        className="container-fluid"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="row p-0">
          <div className="col-lg-2 p-0">
            <Sidebar />
          </div>
          <div className="col-lg-10  p-0">
            <div className="row ">
              <div className="col-lg-12 p-0">
                <Navbar />
              </div>
              <div className="col-lg-12 ">
                <Viewsalescom />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
