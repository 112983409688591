import {
  LOGIN_SUCCESS,
  SAVE_VIEW_EMPLOYEE,
  EMPLOYEE_DETAILS,
  LOGOUT,
} from "./ActionTypes";

const initialState = {
  login: false,
  employeeDetails: {},
  viewEmployee: {},
  downloadPayslip: {},
  viewCustomer: {},
  viewSales: {},
  viewQuotation: {},
  viewStudent: {},
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
      };

    case EMPLOYEE_DETAILS:
      return {
        ...state,
        employeeDetails: action.payload,
      };
    case SAVE_VIEW_EMPLOYEE:
      return {
        ...state,
        viewEmployee: action.payload,
      };
    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default Reducer;
